$icomoon-font-family: "MBS_font" !default;
$icomoon-font-path: "~mbs-fonts/MBS_font/font/" !default;

$ico-powerShell: "\e96c";
$ico-bash: "\e96d";
$ico-triangle-o: "\e96b";
$ico-snmp: "\e96a";
$ico-calendar-alt: "\e968";
$ico-hdd-solid: "\e969";
$ico-space-bar: "\e966";
$ico-exclamation-square: "\e967";
$ico-SquareT: "\e962";
$ico-SquareS: "\e963";
$ico-GoogleDrive: "\e964";
$ico-Envelope: "\e960";
$ico-external-link-square: "\f14c";
$ico-circle: "\f111";
$ico-exclamation-circle: "\f06a";
$ico-plus-circle: "\f055";
$ico-minus-circle: "\f056";
$ico-question-circle: "\f059";
$ico-info-circle: "\f05a";
$ico-chevron-circle-left: "\f137";
$ico-chevron-circle-right: "\f138";
$ico-chevron-circle-up: "\f139";
$ico-chevron-circle-down: "\f13a";
$ico-play-circle: "\f144";
$ico-pause-circle: "\f28b";
$ico-circleArrowUp: "\e954";
$ico-arrow-circle-up: "\e954";
$ico-circleArrowRight: "\e955";
$ico-arrow-circle-right: "\e955";
$ico-circleArrowDown: "\e953";
$ico-arrow-circle-down: "\e953";
$ico-circleArrowLeft: "\e956";
$ico-arrow-circle-left: "\e956";
$ico-stop-circle: "\f28d";
$ico-failed: "\e911";
$ico-times-circle: "\e911";
$ico-success: "\e912";
$ico-check-circle: "\e912";
$ico-error: "\e915";
$ico-error-circle: "\e915";
$ico-Restart: "\e95e";
$ico-listClock: "\e95c";
$ico-ListClock: "\e95c";
$ico-listStar: "\e95d";
$ico-ListStar: "\e95d";
$ico-creditDollar: "\e95b";
$ico-CreditDollar: "\e95b";
$ico-Target: "\e95a";
$ico-RD: "\e959";
$ico-RMM: "\e965";
$ico-Terminal: "\e958";
$ico-boxClock: "\e957";
$ico-BoxClock: "\e957";
$ico-Chat: "\e952";
$ico-Chart: "\e951";
$ico-EllipsisH: "\e950";
$ico-EllipsisV: "\e95f";
$ico-EnvelopePaperclip: "\e94f";
$ico-ComputerUpload: "\e94d";
$ico-ComputerDownload: "\e94e";
$ico-InfoCloud: "\e94c";
$ico-InfoCircle: "\e94b";
$ico-MinusThin: "\e900";
$ico-PlusThin: "\e901";
$ico-GridView: "\e947";
$ico-ListView: "\e948";
$ico-i: "\e946";
$ico-BarChart: "\e931";
$ico-Briefcase: "\e932";
$ico-Camera: "\e933";
$ico-Certificate: "\e934";
$ico-ClockO: "\e935";
$ico-Cloud: "\e936";
$ico-Download: "\e937";
$ico-Edit: "\e938";
$ico-EnvelopeSquare: "\e939";
$ico-Globe: "\e93a";
$ico-Home: "\e93b";
$ico-Key: "\e93c";
$ico-Laptop: "\e93d";
$ico-Magic: "\e93e";
$ico-Money: "\e93f";
$ico-PieChart: "\e940";
$ico-Plug: "\e941";
$ico-Random: "\e942";
$ico-rss: "\e943";
$ico-Tasks: "\e944";
$ico-Wrench: "\e945";
$ico-Info: "\e92b";
$ico-Basket: "\e92c";
$ico-Lock2: "\e92d";
$ico-Gear: "\e92e";
$ico-Office365: "\e92f";
$ico-GoogleApps: "\e930";
$ico-Pencil: "\e92a";
$ico-Calendar: "\e926";
$ico-Contact: "\e927";
$ico-Drive: "\e928";
$ico-MailBox: "\e929";
$ico-OverdueSettings: "\e925";
$ico-Clock: "\e924";
$ico-Compare: "\e923";
$ico-Eye: "\e920";
$ico-Mail: "\e921";
$ico-Users: "\e922";
$ico-User: "\e91f";
$ico-Lock: "\e918";
$ico-arrLeft: "\e919";
$ico-arrRight: "\e91e";
$ico-arrUp: "\e91c";
$ico-arrDown: "\e91d";
$ico-Minus: "\e909";
$ico-Plus: "\e917";
$ico-Excel: "\e907";
$ico-Refresh: "\e908";
$ico-FilesView: "\e905";
$ico-PlanView: "\e906";
$ico-Purge: "\e904";
$ico-Close: "\e903";
$ico-Backup: "\e902";
$ico-OpenHistory: "\e90a";
$ico-Restore: "\e90b";
$ico-tick: "\e90c";
$ico-Cloud-backup: "\e90d";
$ico-local-storage: "\e90e";
$ico-ec2linux: "\5b";
$ico-hyperv: "\5e";
$ico-windows: "\60";
$ico-databased: "\5f";
$ico-vmware: "\5d";
$ico-exchange: "\5c";
$ico-ec2mac: "\54";
$ico-ec2gear: "\58";
$ico-ec2box: "\52";
$ico-ec2drive: "\59";
$ico-ec2win: "\55";
$ico-ec2android: "\56";
$ico-ec2server: "\5a";
$ico-glacier: "\64";
$ico-azurewin: "\67";
$ico-ec2: "\53";
$ico-consistencycheck: "\65";
$ico-file: "\63";
$ico-imagebased: "\61";
$ico-arrowLeftSm: "\e90f";
$ico-arrowRightSm: "\e910";
$ico-arrowUpSm: "\e91a";
$ico-arrowDownSm: "\e91b";
$ico-cross: "\e913";
$ico-ok: "\e914";
$ico-arrowDown: "\e916";
$ico-Delete-text: "\e949";
$ico-Search: "\e94a";
$ico-SearchNext: "\e961";

