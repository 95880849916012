@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;
@use '../../basic/variables' as pv;

.mbs-footer {
  border-top: pv.$footer-border-width solid pv.$footer-border-color;
  background: pv.$footer-bg;
  color: pv.$footer-color;

  &-inner {
    align-items: center;
    justify-content: space-between;
    height: pv.$footer-height-sm;
    padding: pv.$footer-padding-y pv.$footer-padding-x;

    @include media.media-breakpoint-up(md) {
      height: pv.$footer-height;
    }
  }

  &_copyrights {
    font-size: pv.$footer-font-size;
    text-align: center;
  }

  &_logo {
    display: flex;

    &-link {
      display: block;
      border: 0;
      outline: none;
      text-decoration: none;
    }

    &-img {
      display: block;
      max-width: pv.$footer-logo-max-width;
      max-height: pv.$footer-logo-max-height;
      border: 0;
      outline: none;
    }
  }
}

.-showFooter {
  .mbs-page {
    min-height: calc(100vh - #{pv.$footer-height-sm} - #{v.$header-height});

    @include media-breakpoint-up(md) {
      min-height: calc(100vh - #{pv.$footer-height} - #{v.$header-height});
    }
  }
}
