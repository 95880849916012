@use 'variables' as v;
@use 'mixins/breakpoints' as media;

@each $breakpoint in map-keys(v.$grid-breakpoints) {
  @include media.media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 0 through 10 {
      .flex-grow#{$infix}-#{$i} {
        flex-grow: $i !important;
      }

      .flex-shrink#{$infix}-#{$i} {
        flex-shrink: $i !important;
      }
    }

    @for $i from 0 through 100 {
      .flex-basis#{$infix}-#{$i} {
        flex-basis: $i !important;
      }
    }

    @each $size, $length in $spacers {
      .d-flex,
      .d-inline-flex {
        &.flex-gap#{$infix}-#{$size} {
          gap: $length !important;
        }
      }
    }
  }
}
