@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

@mixin red-dot {
  background-color: v.$red !important;
  border-radius: 50%;
  height: 8px !important;
  width: 8px !important;
  color: v.$red !important;
  font-size: 2px !important;
}

[data-badge-changelog]::after {
  top: 4px !important;
  right: 4px !important;
  @include red-dot();
}

.app-update-dot {
  &:after {
    display: inline-flex;
    position: absolute;
    content: '';

    @include red-dot();
  }
}
