@import "prismjs/themes/prism";

.text-area-code-editor {
  caret-color: black;
  white-space: nowrap;
  overflow: auto;
  text-align: left;
  display: block;
  resize: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  color: transparent;
  background: transparent;
  z-index: 2;
}
