@use '../../basic/variables' as v;

.mbs-table_filter {
  align-items: center;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: v.$table-filter-height;
    font-size: v.$table-filter-item-font-size;
    font-weight: v.$table-filter-item-font-weight;

    .ctrl {
      font-weight: inherit;
      line-height: normal;
    }
  }

  &-divider {
    &:before {
      content: '';
      display: block;
      width: v.$table-border-width;
      height: v.$table-filter-divider-height;
      background: v.$table-border-color;
    }
  }
}
