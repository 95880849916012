@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.mbs-agent {
  display: inline-block;
  position: relative;
  width: cv.$agent-ico-size;
  height: cv.$agent-ico-size;
  background-image: url('../../../assets/images/agents/backup.svg');
  background-repeat: no-repeat;
  background-size: contain;

  &-sm {
    width: cv.$agent-ico-size-sm;
    height: cv.$agent-ico-size-sm;
  }

  &-md {
    width: cv.$agent-ico-size-md;
    height: cv.$agent-ico-size-md;
  }

  &-lg {
    width: cv.$agent-ico-size-lg;
    height: cv.$agent-ico-size-lg;
  }
}

@each $agent in cv.$agents {
  .mbs-agent_#{$agent} {
    background-image: url('../../../assets/images/agents/#{$agent}.svg');
  }
}
