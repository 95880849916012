@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

.mbs-processes-charts {
  &_chart {
    position: relative;
    cursor: pointer;

    &-ico {
      position: absolute;
      top: 9px;
      right: v.$gutter;
    }

    &:hover {
      background: mix(v.$primary, v.$white, 10%);
    }
  }
}
