@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.mbs-feedback {
  display: block;
  position: fixed;
  z-index: cv.$feedback-zindex;
  right: cv.$feedback-position-x;
  bottom: cv.$feedback-position-bottom;
  min-width: cv.$feedback-min-width;
  max-width: cv.$feedback-max-width;
  padding: cv.$feedback-padding-y cv.$feedback-padding-x;
  border-radius: cv.$feedback-border-radius;
  background: cv.$feedback-bg;
  color: cv.$feedback-color;

  &-icon {
    position: relative;
    top: cv.$feedback-icon-offset-y;
    font-size: cv.$feedback-icon-size;
  }

  .btn {
    &.btn-white {
      border-color: darken(v.$white, 8.6%);
      background: v.$white;

      &:hover,
      &:focus {
        background: darken(v.$white, 8.6%);
      }
    }
  }

  .ctrl {
    &.ctrl-white {
      color: v.$white;

      &:hover,
      &:focus {
        color: darken(v.$white, 8.6%);
      }
    }
  }

  @media only screen and (max-width: 450px) {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}
