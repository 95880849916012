@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

$chat-button-offset: 610px;
$chat-button-offset-sm: 42px;
$chat-button-min-width: 205px;
$chat-button-max-width: 280px;
$chat-button-height: 32px;
$chat-button-bg: v.$brand;
$chat-button-padding-x: 20px;
$chat-button-border-radius: v.$border-radius-lg;
$chat-button-ico-size: 3px;
$chat-button-ico-margin-right: 9px;
$chat-button-font-size: v.$h5-font-size;
$chat-button-font-weight: v.$headings-font-weight;
$chat-button-line-height: v.$h5-line-height;
$chat-button-letter-spacing: v.$h5-letter-spacing;

$chat-dialog-header-height: 46px;
$chat-dialog-header-padding-x: 20px;
$chat-dialog-header-bg: v.$brand;
$chat-dialog-header-color: v.$white;
$chat-dialog-header-ico-size: 16px;
$chat-dialog-header-ico-margin-right: 9px;
$chat-dialog-header-font-size: v.$h5-font-size;
$chat-dialog-header-font-weight: v.$headings-font-weight;
$chat-dialog-header-line-height: v.$h5-line-height;
$chat-dialog-header-letter-spacing: v.$h5-letter-spacing;
$chat-dialog-header-controls-ico-size: 14px;
$chat-dialog-header-controls-padding: 3.5px;
$chat-dialog-header-controls-size: $chat-dialog-header-controls-ico-size + (2 * $chat-dialog-header-controls-padding);
$chat-dialog-header-controls-color: v.$white;
$chat-dialog-border-radius: v.$border-radius-lg;
$chat-dialog-content-padding-y: 18px;
$chat-dialog-content-padding-x: $chat-dialog-header-padding-x;
$chat-dialog-col-gutter: 6px;
