@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-switcher;

#{$componentSelector} {
  display: flex;
  flex-wrap: wrap;
  min-height: v.$switcher-min-height;

  .#{$componentSelector} {
    display: flex;
    max-width: 100%;
    margin-bottom: 0;
    gap: v.$switcher-track-gap;

    &_toggler {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: v.$switcher-toggler-size;
      height: v.$switcher-toggler-size;
      overflow: hidden;
      border-radius: 50%;
      background: v.$switcher-color-secondary;
      text-align: center;

      &:before {
        @include m.icomoon();
        font-size: v.$switcher-toggler-icon-size;
        line-height: v.$switcher-toggler-size;
      }
    }

    &_input {
      @extend %check-radio-input-hidden;

      + .#{$componentSelector} {
        &_track {
          display: flex;
          position: relative;
          top: v.$switcher-track-offset-top;
          flex-shrink: 0;
          width: v.$switcher-track-width;
          height: v.$switcher-track-height;
          padding: v.$switcher-track-padding;
          border-radius: v.$switcher-track-border-radius;
          background: v.$switcher-color-primary;

          .#{$componentSelector} {
            &_toggler {
              &:before {
                content: i.$ico-Close;
                color: v.$switcher-color-primary;
              }
            }
          }
        }
      }

      &:disabled {
        + .#{$componentSelector} {
          &_track {
            @extend %disabled-element;
            background: v.$switcher-disabled-color-primary;

            .#{$componentSelector} {
              &_toggler {
                background: v.$switcher-disabled-color-secondary;

                &:before {
                  color: v.$switcher-disabled-color-primary;
                }
              }
            }
          }
        }
      }

      &:checked {
        + .#{$componentSelector} {
          &_track {
            flex-direction: row-reverse;
            background: v.$switcher-accent-color;

            .#{$componentSelector} {
              &_toggler {
                &:before {
                  content: i.$ico-ok;
                  color: v.$switcher-accent-color;
                  font-size: v.$switcher-toggler-icon-checked-size;
                }
              }
            }
          }
        }

        &:disabled {
          + .#{$componentSelector} {
            &_track {
              background: v.$switcher-accent-disabled-color;

              .#{$componentSelector} {
                &_toggler {
                  &:before {
                    color: v.$switcher-accent-disabled-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_content {
      display: flex;
      max-width: 100%;
      gap: v.$switcher-track-gap;

      &.-reverse {
        flex-direction: row-reverse;
      }
    }

    &_label {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    &.-reverse {
      flex-direction: row-reverse;
    }

    &_subtitle,
    &_description {
      @extend %subtitle-description;
      display: block;
      flex-shrink: 0;
      width: 100%;

      &.-inside {
        padding-left: v.$switcher-subtitle-description-inside-padding-x;

        &.-reverse {
          padding-right: v.$switcher-subtitle-description-inside-padding-x;
          padding-left: 0;
        }
      }
    }

    &_subtitle {
      @extend %subtitle;
    }
  }

  & + & {
    margin-top: v.$switcher-margin-top;
  }
}
