@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

// Project variables only

// Footer
$footer-padding-x: v.$gutter;
$footer-padding-y: 1.5 * v.$gutter;
$footer-logo-max-width: 255px;
$footer-logo-max-height: 55px;
$footer-height: calc((2 * #{$footer-padding-y}) + #{$footer-logo-max-height});
$footer-height-sm: calc((2 * #{$footer-padding-y}) + (3 * #{$footer-logo-max-height}));
$footer-bg: v.$gray-100;
$footer-border-width: v.$border-width;
$footer-border-color: v.$gray-300;
$footer-font-size: 0.8rem;
$footer-color: v.$gray-800;

// Toasts
$toasts-position-bottom: 60px;
$toasts-position-bottom-sm: 35px;

//Page / temporary variables, remove after MBSUIK-351 finished
$page-content-padding-top: 1rem;
$page-content-padding-bottom: $page-content-padding-top;
