.sticky-buttons {
  position: sticky;
  z-index: 2;
  bottom: 0;
  margin-top: -20px;
  padding-left: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  background: #f9f9f9;
}
