@use '../../basic/variables' as v;

$componentSelector: mbs-loader;

.#{$componentSelector} {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  @mixin setSpinnerSize($spinnerSize) {
    width: $spinnerSize;
    height: $spinnerSize;
  }

  &_spinner {
    @include setSpinnerSize(v.$mbs-loader-size);
    flex: 0 0 auto;
    overflow: hidden;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    border: v.$mbs-loader-border;
    border-radius: 50%;
    border-top-color: transparentize(v.$mbs-loader-color, v.$mbs-loader-1st-spinner-transparency);

    &:before {
      content: '';
      position: absolute;
      top: v.$mbs-loader-2nd-spinner-position;
      right: v.$mbs-loader-2nd-spinner-position;
      bottom: v.$mbs-loader-2nd-spinner-position;
      left: v.$mbs-loader-2nd-spinner-position;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
      border: v.$mbs-loader-border;
      border-radius: 50%;
      border-top-color: v.$mbs-loader-color;
    }

    &:after {
      content: '';
      position: absolute;
      top: v.$mbs-loader-3rd-spinner-position;
      right: v.$mbs-loader-3rd-spinner-position;
      bottom: v.$mbs-loader-3rd-spinner-position;
      left: v.$mbs-loader-3rd-spinner-position;
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
      border: v.$mbs-loader-border;
      border-radius: 50%;
      border-top-color: transparentize(v.$mbs-loader-color, v.$mbs-loader-3rd-spinner-transparency);
    }

    //Sizes

    &-small {
      @include setSpinnerSize(v.$mbs-loader-size-sm);
    }

    &-xsmall {
      @include setSpinnerSize(v.$mbs-loader-size-xs);
      &:before {
        top: v.$mbs-loader-2nd-spinner-position-xs;
        right: v.$mbs-loader-2nd-spinner-position-xs;
        bottom: v.$mbs-loader-2nd-spinner-position-xs;
        left: v.$mbs-loader-2nd-spinner-position-xs;
      }
      &:after {
        top: v.$mbs-loader-3rd-spinner-position-xs;
        right: v.$mbs-loader-3rd-spinner-position-xs;
        bottom: v.$mbs-loader-3rd-spinner-position-xs;
        left: v.$mbs-loader-3rd-spinner-position-xs;
      }
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin loaderColorVariant($color) {
  border-top-color: transparentize($color, v.$mbs-loader-1st-spinner-transparency);
  &:before {
    border-top-color: $color;
  }
  &:after {
    border-top-color: transparentize($color, v.$mbs-loader-3rd-spinner-transparency);
  }
}

@each $color, $value in v.$theme-colors {
  .#{$componentSelector}_spinner-#{$color} {
    @include loaderColorVariant($value);
  }
}
