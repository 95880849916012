@use '../../../basic/variables' as v;
@use '../../form-controls/datepicker/variables' as cv;

$componentSelector: mbs-datepicker-range;

.#{$componentSelector} {
  width: fit-content;
  .date-input{
    width: fit-content;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
}
.#{$componentSelector}_dropdown {
  padding: 0px;
  .mbs-datepicker_datepicker {
    border: none !important;
    padding: 0;
  }

  .ngb-dp-header{
    padding: 6px 0;
  }

  .ngb-dp-content {
    padding-top: 5px;
    border-top: cv.$datepicker-border-width solid cv.$datepicker-border-color;
    border-bottom: cv.$datepicker-border-width solid cv.$datepicker-border-color;
  }

  .ngb-dp-month-name {
    font-weight: 700;
  }

  .ngb-dp-weekday {
    font-weight: 600;
    color: $gray-900;
  }

  .ngb-dp-weekday, .ngb-dp-week, .ngb-dp-month-name {
    background: white;
  }

  .ngb-dp-week {
    padding: 0 0.5rem 0.5rem 0.5rem !important;
  }

  .mbs-datepicker_datepicker-day {
    &.range {
      background-color: cv.$datepicker-item-selected-bg !important;
      color: cv.$datepicker-item-selected-color !important;
    }

    &.faded {
      background-color: cv.$datepicker-item-faded-bg;
      color: cv.$datepicker-item-selected-color;
    }
  }
}
