@import 'node_modules/mbs-ui-kit/styles/basic/variables';
@import 'node_modules/mbs-ui-kit/styles/basic/mixins';

// swiper
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/pagination/pagination.scss';
@import 'node_modules/swiper/modules/navigation/navigation.scss';
@import 'libs/mbs-ui-kit/styles/components/swiper/swiper';

// Bootstrap and Shared components
@import 'libs/mbs-ui-kit/styles';
/* autoprefixer grid: on */
@import 'libs/mbs-fonts/fonts';

// Project styles
@import 'projects/mbs-ui/styles/styles';
