@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'projects/mbs-ui/styles/basic/variables' as pv;

.mbs-page_content {
  padding-top: pv.$page-content-padding-top;
  padding-bottom: pv.$page-content-padding-bottom;
}

.mbs-page-fixed_header {
  z-index: v.$zindex-sticky;
  position: fixed;
  left: 20px;
  right: 20px;
  top: 0;
  padding-top: 55px;
  background-color: white;
}
