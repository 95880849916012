@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins' as m;
@use 'node_modules/mbs-ui-kit/styles/components/form-controls/select/variables' as cv;

.mbs-select {
  &.mbs-select-editable {
    .ng-select {

      &.ng-select-multiple {
        .ng-select-container {
          .ng-value-container {
            .ng-value {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
