@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins' as m;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/border-radius' as br;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

@use 'variables' as cv;

// Chat button
.embeddedServiceHelpButton {
  z-index: v.$zindex-fixed !important;

  .helpButton {
    right: cv.$chat-button-offset-sm !important;
    bottom: 0 !important;
    left: auto !important;
    height: cv.$chat-button-height !important;

    .uiButton {
      justify-content: center !important;
      min-width: cv.$chat-button-min-width !important;
      max-width: cv.$chat-button-max-width !important;
      height: 100% !important;
      background: cv.$chat-button-bg !important;
      font-family: v.$font-family-sans-serif !important;
      @include m.padding-x(cv.$chat-button-padding-x !important);
      @include br.border-top-radius(cv.$chat-button-border-radius !important);
      @include br.border-bottom-radius(0 !important);

      .embeddedServiceIcon {
        flex-shrink: 0 !important;
        margin-right: cv.$chat-button-ico-margin-right !important;
        &:before {
          @include m.icomoon();
          content: i.$ico-Chat;
          font-size: cv.$chat-button-ico-size !important;
        }
      }

      .helpButtonLabel {
        flex-grow: 0 !important;

        .message {
          font-size: cv.$chat-button-font-size;
          font-weight: cv.$chat-button-font-weight;
          letter-spacing: cv.$chat-button-letter-spacing;
          line-height: cv.$chat-button-line-height;
        }
      }

      &:focus {
        .helpButtonLabel {
          text-decoration: none !important;
        }
      }
    }

    @media only screen and (min-width: 48em) {
      right: auto !important;
      left: cv.$chat-button-offset !important;
    }

    @include media.media-breakpoint-down(lg) {
      right: cv.$chat-button-offset-sm !important;
      left: auto !important;
    }
  }
}
