@use 'mixins' as m;
@use 'variables' as v;

html {
  font-family: v.$font-family-base;
  font-size: v.$font-size-base;
}

body {
  background: v.$body-bg;
  font-family: v.$font-family-base;
  font-size: v.$font-size-base;
}

.nowrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-overflow {
  @include m.textOverflow();
}

.text-start {
  text-align: left !important;
}

.text-no-overflow {
  overflow: unset !important;
}

.border-radius-sm {
  border-radius: v.$border-radius-sm;
}

.border-radius-sm-important {
  border-radius: v.$border-radius-sm !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-base {
  line-height: v.$line-height-base !important;
}

.list-style-inside {
  list-style-position: inside;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: transparentize(v.$gray-900, 0.6) !important;
}

.small-min-width {
  min-width: 360px !important;
}

@import 'helpers';
@import 'flex';
@import 'bg';
@import 'colors';
@import 'overflow';
@import 'typography';
@import 'layout';
@import 'page';
