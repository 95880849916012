@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

.mbs-download-build {
  &_build-select {
    // the way to trigger mouse-events inside ng-value
    .ng-value {
      z-index: 100;
    }
  }
}
