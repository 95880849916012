@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;
@use '../../basic/mixins/breakpoints' as media;

// Container that the modal scrolls within
.mbs-modal {
  &_header {
    display: block;
    flex-shrink: 0;
    border-bottom: v.$modal-header-border-width solid v.$modal-header-border-color;

    &.-sm {
      padding: v.$modal-header-sm-padding;
    }
  }

  &_title {
    flex-grow: 1;
    min-height: v.$modal-title-min-height;
    font-size: v.$modal-title-font-size;
    line-height: v.$modal-title-line-height;

    .ico {
      margin-right: v.$modal-header-mg-r;
      font-size: v.$modal-header-font-size;
    }
  }

  &_footer {
    flex-shrink: 0;
  }

  // For modal inside other components
  &.-abs {
    position: absolute;
  }
}

// Actual modal
.modal {
  &-body {
    flex-grow: 2;
    padding: v.$modal-body-padding-y v.$modal-body-padding-x;
    overflow-y: auto;

    .mbs-form_content {
      padding: 0;
    }

    [modal-body] {
      height: 100%;
    }
  }

  &-footer {
    padding: v.$modal-footer-padding-y v.$modal-footer-padding-x;
    gap: v.$modal-footer-margin-between;

    > * {
      margin: unset;
    }
  }

  &-content {
    border: v.$modal-content-border-width solid v.$modal-content-border-color;
    background-color: v.$modal-content-bg;
    box-shadow: v.$modal-content-box-shadow;
    color: v.$modal-content-color;
    @include br.border-radius(v.$modal-content-border-radius);

    app-modal,
    mbs-modal {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    }

    &.-collapsing {
      min-height: v.$modal-collapsing-min-height;
      max-height: v.$modal-collapsing-max-height;

      .modal-content {
        &-inner {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        }
      }

      app-modal,
      mbs-modal {
        min-height: v.$modal-collapsing-min-height;
      }
    }
  }

  &-dialog {
    &.-fullScreen {
      max-width: 100vw;
      margin: 0;

      > .modal-content {
        width: 100vw;
        min-height: 100vh;
        border-radius: 0;

        > app-modal,
        > mbs-modal,
        > .modal-content-inner {
          height: 100vh;
        }
      }
    }
  }

  &-res {
    &-xl {
      width: v.$modal-res-xl;
      margin: auto;

      @include media.media-breakpoint-up(sm) {
        max-width: v.$modal-res-xl;
      }
    }

    &-lg {
      width: v.$modal-res-lg;
      margin: auto;

      @include media.media-breakpoint-up(sm) {
        max-width: v.$modal-res-lg;
      }
    }

    &-md {
      width: v.$modal-res-md;
      margin: auto;

      @include media.media-breakpoint-up(sm) {
        max-width: v.$modal-res-md;
      }
    }

    &-sm {
      width: v.$modal-res-sm;
      margin: auto;

      @include media.media-breakpoint-up(sm) {
        max-width: v.$modal-res-sm;
      }
    }
  }

  &-backdrop-inside {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-inside {
    position: absolute;

    &.modal.show {
      > .modal-dialog {
        height: 100%;
        min-height: unset;
        margin: auto;

        > .modal-content {
          &.-collapsing {
            max-height: v.$modal-inside-collapsing-max-height;
          }
        }
      }
    }
  }

  &_content {
    &-ico {
      &.-circle {
        display: block;
        width: v.$modal-content-ico-circle-size;
        height: v.$modal-content-ico-circle-size;
        border-radius: 50%;
        font-size: v.$modal-content-ico-circle-font-size;
        line-height: v.$modal-content-ico-circle-size;
        text-align: center;
      }
    }
  }
}

body {
  > .modal {
    .modal-res {
      &-xl {
        width: v.$modal-res-xl;
        max-width: v.$modal-xl;
        margin: auto;

        @include media.media-breakpoint-up(sm) {
          width: v.$modal-res-xl;
          max-width: v.$modal-xl;
        }
      }

      &-lg {
        width: v.$modal-res-xl;
        max-width: v.$modal-xl;
        margin: auto;

        @include media.media-breakpoint-up(sm) {
          width: v.$modal-res-lg;
          max-width: v.$modal-lg;
        }
      }

      &-md {
        width: v.$modal-res-xl;
        max-width: v.$modal-xl;
        margin: auto;

        @include media.media-breakpoint-up(sm) {
          width: v.$modal-res-lg;
          max-width: v.$modal-lg;
        }

        @include media.media-breakpoint-up(md) {
          width: v.$modal-res-md;
          max-width: v.$modal-md;
        }
      }

      &-sm {
        width: v.$modal-res-xl;
        max-width: v.$modal-xl;
        margin: auto;

        @include media.media-breakpoint-up(sm) {
          width: v.$modal-res-lg;
          max-width: v.$modal-lg;
        }

        @include media.media-breakpoint-up(md) {
          width: v.$modal-res-md;
          max-width: v.$modal-md;
        }

        @include media.media-breakpoint-up(xl) {
          width: v.$modal-res-sm;
          max-width: v.$modal-sm;
        }
      }
    }
  }
}

// Scale up the modal
@include media.media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: v.$modal-sm;
  }

  .modal-sm,
  .modal-md {
    max-width: v.$modal-sm;
  }
}

@include media.media-breakpoint-up(md) {
  .modal-md {
    max-width: v.$modal-md;
  }

  .modal-lg,
  .modal-xl {
    max-width: v.$modal-lg;
  }
}

@include media.media-breakpoint-up(lg) {
  .modal-lg,
  .modal-xl {
    max-width: v.$modal-lg;
  }
}

@include media.media-breakpoint-up(xl) {
  .modal-xl {
    max-width: v.$modal-xl;
  }
}
