@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

$componentSelector: mbs-tree;

#{$componentSelector} {
  display: block;

  .#{$componentSelector} {
    &-item {
      position: relative;
      top: calc((-1 * v.$tree-item-padding-y) + v.$check-radio-input-offset-top);

      @for $i from 0 through 100 {
        &.-level-#{$i} {
          margin-left: v.$tree-item-level-offset * $i;
        }
      }

      &_label {
        display: flex;
        position: relative;
        flex-wrap: nowrap;
        width: calc(100% + (2 * #{v.$tree-item-inner-padding-x}));
        height: v.$tree-item-height;
        @include m.margin-x(-1 * v.$tree-item-inner-padding-x);

        > * {
          display: flex;
          @include m.padding-y(v.$tree-item-padding-y);
          @include m.padding-x(v.$tree-item-inner-padding-x);
        }

        &.-selected {
          background: v.$tree-item-selected-bg;

          &.-hover {
            &:hover {
              background: v.$table-grid-row-selected-hover-bg;
            }
          }
        }

        &.-hover {
          &:hover {
            background: v.$tree-item-hover-bg;
          }
        }
      }

      &_arrow {
        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
          flex-shrink: 0;
          width: v.$tree-item-arrow-btn-width;
          height: v.$tree-item-height;
          @include m.padding-y(0);
          @include m.padding-x(0);

          > .ctrl {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            &:before {
              content: '';
              display: block;
              position: relative;
              left: v.$tree-item-inner-padding-x;
              width: 0;
              height: 0;
              border-top: v.$tree-item-arrow-icon-size solid transparent;
              border-right: 0;
              border-bottom: v.$tree-item-arrow-icon-size solid transparent;
              border-left: v.$tree-item-arrow-icon-size solid v.$tree-item-arrow-color;
            }

            &.-expanded {
              &:before {
                transform: rotate(90deg);
              }
            }

            &.-loading {
              &:before {
                display: none;
              }
            }

            &:hover {
              &:before {
                border-left-color: v.$tree-item-arrow-color-hover;
              }
            }
          }
        }
      }

      &_text,
      &_checkbox {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding-left: v.$tree-item-arrow-btn-width;
        overflow: hidden;

        .ctrl {
          @include m.textOverflow();
          line-height: v.$tree-item-height - (2 * v.$tree-item-padding-y);
        }

        &.-hideArrow {
          padding-left: v.$tree-item-inner-padding-x;
        }
      }

      &_checkbox {
        padding-top: v.$tree-item-padding-y - v.$check-radio-input-offset-top;

        .mbs-checkbox {
          max-width: 100%;

          &_label {
            overflow: hidden;
            padding-left: v.$check-radio-focus-width;
            margin-left: v.$check-radio-focus-width;

            &-content {
              @include m.textOverflow();
            }
          }
        }
      }

      &.-disabled {
        @extend %disabled-element;

        * {
          @extend %disabled-element;
        }
      }

      &:not(:first-child) {
        margin-top: v.$tree-item-margin-top;
      }
    }

    &:not(.-virtual-scroll) {
      @include m.margin-x(-1 * v.$tree-item-padding-x);

      .#{$componentSelector} {
        &-item {
          @include m.padding-x(v.$tree-item-padding-x);
        }
      }
    }
  }
}

@import 'tree-file';
