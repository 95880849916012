@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

$feedback-position-x: 15px;
$feedback-position-bottom: 15px;
$feedback-max-width: 350px;
$feedback-min-width: calc(360px - (2 * #{v.$grid-gutter-width}));
$feedback-bg: v.$primary;
$feedback-color: v.$white;
$feedback-border-radius: v.$border-radius;
$feedback-zindex: 1300;
$feedback-padding-y: 16px;
$feedback-padding-x: 16px;
$feedback-icon-size: 22px;
$feedback-icon-offset-y: calc(((#{$feedback-icon-size} / 2) - (#{v.$h5-font-size} / 2)) / 2);
