@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.mbs-storage {
  display: inline-block;
  position: relative;
  width: cv.$storage-ico-size;
  height: cv.$storage-ico-size;
  background-image: url('../../../assets/images/storages/Default.svg');
  background-repeat: no-repeat;
  background-size: contain;

  &-sm {
    width: cv.$storage-ico-size-sm;
    height: cv.$storage-ico-size-sm;
  }

  &-lg {
    width: cv.$storage-ico-size-lg;
    height: cv.$storage-ico-size-lg;
  }
}

@each $storage in cv.$storage-accounts {
  .mbs-storage_#{$storage} {
    background-image: url('../../../assets/images/storages/#{$storage}.svg');
  }
}
