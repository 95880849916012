@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

$componentSelector: mbs-tile;

.#{$componentSelector} {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: v.$tile-border-width solid v.$tile-border-color;
  border-radius: v.$tile-border-radius;
  background: v.$tile-bg;

  &_header {
    display: flex;
    align-items: center;
    height: v.$tile-header-height;
    @include m.padding-x(v.$tile-padding-x);
    border-bottom: v.$tile-border-width solid v.$tile-border-color;
    @include br.border-top-radius(v.$tile-border-radius);
    background: v.$tile-header-bg;

    &-row {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: calc(100% + #{v.$form-grid-gutter-width});
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &_body {
    flex-grow: 2;
    padding: v.$tile-body-pd;
  }

  &_ico {
    flex-basis: 0;
    flex-shrink: 0;
    max-width: 100%;
    @include m.padding-x(v.$form-grid-gutter-width / 2);
    color: inherit;
    font-size: v.$tile-icon-size;
  }

  &_name {
    display: block;
    flex-basis: 0;
    flex-grow: 2;
    flex-shrink: 0;
    max-width: 100%;
    @include m.padding-x(v.$form-grid-gutter-width / 2);
    @include m.textOverflow();
    color: inherit;
    font-size: v.$tile-name-font-size;
    font-weight: v.$tile-name-font-weight;
    text-align: left;

    mbs-button,
    .ctrl {
      display: block;
      @include m.textOverflow();
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  &_data {
    font-size: v.$tile-data-font-size;
  }

  &:not(.-disabled) {
    &:hover {
      background: v.$tile-hover-bg;

      .#{$componentSelector} {
        &_header {
          background: v.$tile-hover-bg;
        }

        &_body {
          background: v.$tile-bg;
        }
      }
    }
  }

  &.-selectable {
    cursor: pointer;
  }

  &.-disabled {
    opacity: v.$tile-disabled-opacity;
    cursor: v.$disabled-cursor;
  }

  &.-selected {
    border-color: v.$tile-selected-border-color;

    .#{$componentSelector}_header {
      border-bottom-color: v.$tile-selected-border-color;
      background: v.$tile-selected-header-bg;
    }
  }
}

#{$componentSelector} {
  display: block;
  height: 100%;
}
