@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins' as m;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/border-radius' as br;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/box-shadow' as bsh;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/hover' as h;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/transition' as t;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/gradients' as gr;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;
@use 'node_modules/mbs-ui-kit/styles/components/buttons/mixins' as bm;

@use 'variables' as cv;

// Dialog
.modalContainer.embeddedServiceSidebar {
  &.modalContainer {
    font-family: v.$font-family-sans-serif !important;
    font-size: v.$font-size-base !important;
  }

  &.layout-docked {
    .dockableContainer {
      right: auto !important;
      left: v.$grid-gutter-width !important;
      font-family: v.$font-family-sans-serif !important;
      font-size: v.$font-size-base !important;
      @include br.border-top-radius(cv.$chat-dialog-border-radius !important);
      @include br.border-bottom-radius(0 !important);

      [embeddedservice-chatheader_chatheader-host] {
        background: v.$brand;
      }

      @include media.media-breakpoint-down(sm) {
        right: v.$grid-gutter-width !important;
        left: v.$grid-gutter-width !important;
        width: calc(100% - (2 * #{v.$grid-gutter-width})) !important;
        min-width: calc(360px - (2 * #{v.$grid-gutter-width})) !important;
        max-width: calc(100% - (2 * #{v.$grid-gutter-width})) !important;
        @include m.margin-x(0 !important);
      }
    }
  }

  .sidebarHeader {
    &[embeddedService-chatHeader_chatHeader] {
      height: cv.$chat-dialog-header-height;
      @include m.padding-x(cv.$chat-dialog-header-padding-x);
      background: cv.$chat-dialog-header-bg;
      color: cv.$chat-dialog-header-color;

      h2 {
        display: flex;
        align-items: center;
        font-size: cv.$chat-dialog-header-font-size;
        font-weight: cv.$chat-dialog-header-font-weight;
        letter-spacing: cv.$chat-dialog-header-letter-spacing;
        line-height: cv.$chat-dialog-header-line-height;

        &:before {
          @include m.icomoon();
          content: i.$ico-Chat;
          flex-shrink: 0 !important;
          margin-right: cv.$chat-button-ico-margin-right !important;
          font-size: cv.$chat-button-ico-size !important;
        }
      }

      button {
        &[embeddedService-chatHeader_chatHeader] {
          position: relative;
          width: cv.$chat-dialog-header-controls-size;
          min-width: cv.$chat-dialog-header-controls-size;
          height: cv.$chat-dialog-header-controls-size;
          min-height: cv.$chat-dialog-header-controls-size;
          color: cv.$chat-dialog-header-controls-color;
          @include m.padding(cv.$chat-dialog-header-controls-padding);

          &:after {
            @include m.icomoon();
            position: absolute;
            bottom: cv.$chat-dialog-header-controls-padding;
            left: calc(50% - (#{cv.$chat-dialog-header-controls-ico-size} / 2));
            font-size: cv.$chat-dialog-header-controls-ico-size;
          }

          lightning-icon {
            display: none;
          }

          &:hover,
          &:focus {
            border: 0;
            outline: none;
            opacity: 0.8;
            box-shadow: none;

            &:before {
              display: none;
            }
          }

          &.minimizeButton {
            &:after {
              content: i.$ico-Minus;
              line-height: 0.3;
            }
          }

          &.closeButton {
            &:after {
              content: i.$ico-Close;
            }
          }
        }
      }
    }

    &.embeddedServiceSidebarMinimizedDefaultUI {
      &.minimizedContainer {
        right: auto !important;
        left: v.$grid-gutter-width !important;
        justify-content: flex-start;
        min-width: cv.$chat-button-min-width;
        max-width: cv.$chat-button-max-width;
        height: cv.$chat-button-height;
        border: 0;
        background: cv.$chat-button-bg;
        font-family: v.$font-family-sans-serif;
        @include m.padding-x(cv.$chat-button-padding-x);
        @include br.border-top-radius(cv.$chat-button-border-radius);
        @include br.border-bottom-radius(0);

        .content {
          padding: 0;
          color: inherit;

          .messageContent {
            justify-content: center;
            margin: 0;

            .embeddedServiceIcon {
              flex-shrink: 0 !important;
              margin-right: cv.$chat-button-ico-margin-right !important;
              &:before {
                @include m.icomoon();
                content: i.$ico-Chat;
                font-size: cv.$chat-button-ico-size !important;
              }

              svg {
                display: none;
              }
            }

            .minimizedText {
              font-size: cv.$chat-button-font-size;
              font-weight: cv.$chat-button-font-weight;
              letter-spacing: cv.$chat-button-letter-spacing;
              line-height: cv.$chat-button-line-height;
            }
          }
        }

        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .sidebarBody {
    height: calc(100% - #{cv.$chat-dialog-header-height});

    .uiButton {
      display: inline-block;
      height: auto;
      border: v.$btn-border-width solid transparent;
      background-color: transparent;
      color: v.$body-color;
      font-family: v.$font-family-sans-serif;
      font-weight: v.$btn-font-weight;
      text-align: center;
      vertical-align: middle;
      pointer-events: auto;
      user-select: none;
      @include bm.button-size(
        v.$btn-height,
        v.$btn-padding-y,
        v.$btn-padding-x,
        v.$btn-gap,
        v.$btn-font-size,
        v.$btn-line-height,
        v.$btn-border-radius
      );
      @include t.transition(v.$btn-transition);

      .label {
        color: inherit;
      }

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: v.$btn-focus-box-shadow;
        text-decoration: none;
      }

      &.disabled,
      &:disabled {
        opacity: v.$btn-disabled-opacity;
        box-shadow: none;
        pointer-events: none;
      }

      &:not(.uiButton--inverse) {
        border-color: darken(v.$primary, 10%);
        color: v.$white;
        @include gr.gradient-bg(v.$primary);
        @include bsh.box-shadow(v.$btn-box-shadow);

        @include h.hover-focus {
          border-color: darken(v.$primary, 20%);
          color: v.$white;
          @include gr.gradient-bg(darken(v.$primary, 7.5%));
        }
      }

      &:not(:disabled):not(.disabled) {
        cursor: pointer;
        &:active,
        &.active {
          @include bsh.box-shadow(v.$btn-active-box-shadow);

          &:focus {
            @include bsh.box-shadow(v.$btn-focus-box-shadow, v.$btn-active-box-shadow);
          }
        }
      }

      &.uiButton--inverse {
        border-color: v.$primary;
        color: v.$primary;

        @include h.hover-focus {
          border-color: v.$primary;
          background-color: v.$primary;
          color: v.$white;
        }

        &:focus,
        &.focus {
          box-shadow: 0 0 0 v.$btn-focus-width rgba(v.$primary, 0.5);
        }

        &.disabled,
        &:disabled {
          background-color: transparent;
          color: v.$primary;
        }
      }
    }
  }

  .embeddedServiceSidebarForm {
    &.formContent {
      padding: cv.$chat-dialog-content-padding-y cv.$chat-dialog-content-padding-x;

      .fieldList {
        display: flex;
        flex-wrap: wrap;
        @include m.margin-y(0);
        @include m.margin-x(-#{cv.$chat-dialog-col-gutter});
        padding: 0;

        .embeddedServiceSidebarFormField {
          display: block;
          width: 100%;
          @include m.margin(0);
          margin-bottom: v.$form-group-margin-bottom;
          @include m.padding-y(0);
          @include m.padding-x(cv.$chat-dialog-col-gutter);

          .split-field-container {
            display: block;
            margin-bottom: 0;

            .uiInput {
              &--input,
              &--select {
                margin-bottom: 0;
              }
            }
          }

          .uiLabel {
            @include m.margin-x(0);
            margin-bottom: v.$label-margin-bottom;
            padding-right: 10px;
            color: v.$label-color;
            font-size: v.$label-font-size;
            line-height: v.$label-line-height;
            cursor: pointer;

            .required {
              right: 0;
              left: auto;
              color: v.$label-required-color;
            }
          }

          .slds-style-inputtext {
            height: v.$input-height;
            padding: v.$input-padding-y v.$input-padding-x;
            border: v.$input-border-width solid v.$input-border-color;
            border-radius: v.$input-border-radius;
            background-color: v.$input-bg;
            color: v.$input-color;

            &:disabled {
              opacity: 1;
              background-color: v.$input-disabled-bg;
              cursor: v.$disabled-cursor;
              pointer-events: none;
            }

            &:not(:disabled) {
              &:focus {
                border-color: v.$input-focus-border-color;
                outline: 0;
                background-color: v.$input-focus-bg;
                box-shadow: 0 0 v.$input-focus-width v.$input-btn-focus-color;
                color: v.$input-focus-color;
              }
            }
          }

          &.inputSplitName {
            width: 50%;
          }
        }
      }
    }
  }

  .embeddedServiceLiveAgentStateChat {
    .chasitorInputWrapper {
      height: 55px;
      min-height: 55px;
      padding: 12px;
      background: v.$gray-200;

      &.embeddedServiceLiveAgentStateChatInputFooter {
        .chasitorControls {
          height: 100%;
          margin: 0;

          .textarea {
            padding: v.$input-padding-y v.$input-padding-x;
            border: v.$input-border-width solid v.$input-border-color;
            border-radius: v.$input-border-radius;
            background-color: v.$input-bg;
            color: v.$input-color;

            &:disabled {
              opacity: 1;
              background-color: v.$input-disabled-bg;
              cursor: v.$disabled-cursor;
              pointer-events: none;
            }

            &:not(:disabled) {
              &:focus {
                border-color: v.$input-focus-border-color;
                outline: 0;
                background-color: v.$input-focus-bg;
                box-shadow: 0 0 v.$input-focus-width v.$input-btn-focus-color;
                color: v.$input-focus-color;
              }
            }
          }
        }
      }
    }
  }

  &.sidebarMaximized {
    @media (hover: none) {
      .dockableContainer {
        right: v.$grid-gutter-width;
        left: v.$grid-gutter-width;
        width: calc(100% - (2 * #{v.$grid-gutter-width})) !important;
        min-width: calc(360px - (2 * #{v.$grid-gutter-width})) !important;
        max-width: calc(100% - (2 * #{v.$grid-gutter-width})) !important;
        @include m.margin-x(0 !important);
      }
    }
  }
}
