$agents: (
  backup,
  rmm,
  connect
) !default;

$agent-ico-size: 48px;
$agent-ico-size-sm: 16px;
$agent-ico-size-md: 24px;
$agent-ico-size-lg: 64px;
