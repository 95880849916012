$storage-accounts: (
  Default,
  AmazonS3,
  AmazonS3Chinese,
  AmazonS3Glacier,
  Aruba,
  BackblazeB2,
  CblWasabi,
  CenturyLink,
  Cloudian,
  DedupServer,
  DreamHost,
  FileSystem,
  FTP,
  GoogleCloud,
  GoogleDrive,
  MicrosoftAzure,
  MicrosoftAzureVM,
  Minio,
  OpenStack,
  OracleCloud,
  S3Compatible,
  Scality,
  Seeweb,
  SFTP,
  Thinkon,
  Tiscali,
  Wasabi,
  UbAmazonS3
) !default;

$storage-ico-size: 48px;
$storage-ico-size-sm: 22px;
$storage-ico-size-lg: 64px;
