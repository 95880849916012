@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;
@use '../../basic/variables' as pv;

mbs-toasts.ngb-toasts {
  max-width: v.$toast-max-width;
  max-height: calc(100vh - #{v.$header-height});
  margin: 0;
  overflow: hidden;

  .mbs-toast {
    min-width: v.$toast-max-width; // Temporary style, remove after MBS-13321
  }

  &[data-position='bottom-left'] {
    top: auto;
    right: auto;
    bottom: pv.$toasts-position-bottom-sm;
    left: v.$grid-gutter-width;

    @include media.media-breakpoint-up(md) {
      bottom: pv.$toasts-position-bottom;
    }
  }

  &[data-position='bottom-right'] {
    top: auto;
    right: v.$grid-gutter-width;
    bottom: pv.$toasts-position-bottom-sm;
    left: auto;

    @include media.media-breakpoint-up(md) {
      bottom: pv.$toasts-position-bottom;
    }
  }
}
